.navbar {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #3d9ebb, #006989);
  box-shadow: 0 4px 8px rgb(112, 111, 111);
  padding: 10px 20px;
  flex-wrap: wrap;
}

.logo-text {
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  position: relative;
  transition: color 1s ease, transform 1s ease;
}

.logo-text::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #00aced;
  transition: width 1s ease;
}

.logo-text:hover {
  color: #ffffff;
  transform: scale(1.1);
  cursor: pointer;
}

.logo-text:hover::after {
  width: 100%;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.nav-links li {
  margin-right: 15px;
}

.nav-links li a {
  color: black;
  text-decoration: none;
}

.search-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.search-box input[type="text"] {
  width: 220px;
  padding: 8px;
  border-radius: 20px;
  border: none;
  margin-right: 10px;
}

.search-box button {
  padding: 8px 15px;
  border: none;
  border-radius: 30%;
  background-color: white;
  color: black;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.buttons button {
  padding: 8px 15px;
  border: none;
  border-radius: 7px;
  background-color: white;
  color: black;
  cursor: pointer;
  margin-left: 10px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .nav-links {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }

  .nav-links li {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .search-box,
  .buttons {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .search-box input[type="text"],
  .search-box button,
  .buttons button {
    width: auto;
    margin: 5px 10px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.navbar, .logo-text, .nav-links li a, .search-box input, .search-box button, .buttons button {
  animation: fadeIn 1s ease-in-out;
}
