body{
  font-family: PT-Serif,serif;
}

.home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('image6.jpeg');
    background-size: cover;
    background-position: center;
    opacity: 0.325;
  }
  
  .description-box {
    z-index: 1;
    width: 95%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease;
    margin-top: 7px;
  }
  
  .description-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Home.css */

.mission-card {
    z-index: 1;
    width: 95%;
    padding: 20px;
   
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease;
    margin-top: 30px;
  }
  
  .mission-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .mission-card h3 {
    margin-bottom: 10px;
  }
  .heading{
    text-align: center;
    margin-top: 2px;
  }
  .heading1{
    text-align: center;
    margin-top: 2px;
  }
  .company-logo {
    width: 100px; 
    height: auto; 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}
.logo2 {
  display: flex;
  justify-content: center;
}

.logo2 img {
  width: 280px; 
  margin-top: 8px;
}
.content{
    background-color: gray;
}
.font{
  font-size: 20px;
  margin: 25px;
}
.head {
  font-size: 2.5em;
  font-weight: bold;
  color: #3d9ebb;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 1s;
  transition: color 0.3s ease;
}

.head:hover {
  color: #00aced;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
